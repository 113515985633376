import React from 'react';
import mimi from '../img/mimi.png';

interface Props {
  title: string;
}

let ContactFirstSection = ({ title }: Props): JSX.Element => {
  return (
    <div className='contactUs__first__contents'>
      {/* <img src={mimi} alt='img'></img> */}
      <div className='contactUs__first__titleBox'>
        <h1>{title}</h1>
      </div>
    </div>
  );
};

export default ContactFirstSection;
