import React from 'react';
import prada from '../img/dutyfree__second1.jpg';
import gucci from '../img/dutyfree__second2.jpg';
import salvatore from '../img/dutyfree__second3.jpg';

let DutyFreeSecondSection = (): JSX.Element => {
  return (
    <div className='dutyFree__second_container'>
      <div className='dutyFree__second_contentBox'>
        <img src={prada} alt='img'></img>
        <img src={gucci} alt='img'></img>
        <img src={salvatore} alt='img'></img>
      </div>
    </div>
  );
};

export default DutyFreeSecondSection;
