import React, { useEffect } from 'react';
import MainFifthSection from '../components/MainFifthSection';
import '../asset/mainFifth.scss';
import EcommerceFirstSection from '../components/EcommerceFirstSection';
import EcommerceSecondSection from '../components/EcommerceSecondSection';
import '../asset/ecommerce.scss';

interface Props {
  handleScroll: () => void;
}

let ECommerce = ({ handleScroll }: Props): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='ecommerce__container'>
      <EcommerceFirstSection />
      {/* <div className='ecommerce__parallax'></div> */}
      {/* <EcommerceSecondSection handleScroll={handleScroll} /> */}
      <div className='ecommerce__parallax'></div>
      <MainFifthSection />
    </div>
  );
};

export default ECommerce;
