import React, { useEffect } from 'react';
import '../asset/contactUs.scss';
import ContactFirstSection from '../components/ContactFirstSection';
import '../asset/notice1.scss';

function Notice2() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='notice__container'>
      <ContactFirstSection title='NEWS & NOTICE' />
      <div className='notice__contentBox'>
        <div className='notice__content'>
          <h1 className='notice__content__title'>
            벤처기업으로 인증되었음을 알려드립니다.
          </h1>
          <p className='notice__content__context'>
            안녕하십니까 ㈜코빠밀뉴입니다.
            <br /> <br />
            먼저 당사에 많은 관심을 가져주셔서 감사드립니다. <br />
            아직 이르지만 2021년 새해 복 많이 받으시고 좋은 일만 가득하시길
            바랍니다.
            <br />
            <br /> 당사는 주주님들의 성원에 힘입어 묵묵히 하고자 하는 일들을 잘
            수행해 나가고 있습니다. 그 일환으로 당사의 기술과 혁신성을 인정받아
            첨부와 같이 벤처기업으로 인정되었음을 알려드립니다. 앞으로도 많은
            관심과 성원을 부탁드리겠습니다. <br />
            <br />
            근로자에 해당하는 경우에는 조세특례제한법 시행령 제14조 7항에 따라서
            투자 당해 종합소득에 대하여 소득공제를 받을 수 있음을 알려드립니다.
            <br />
            자세한 사항은 추후 다시 안내를 드리도록 하겠습니다.
            <br />
            <br /> 감사합니다.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Notice2;
