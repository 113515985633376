import React, { useEffect, useState } from 'react';
import prada from '../img/prada.png';
import burberry from '../img/burberry.png';
import fendi from '../img/fendi.png';
import gucci from '../img/gucci.png';
import salvatore from '../img/salvatore.png';
import valentino from '../img/valentino.png';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../asset/main.scss';

let MainFifthSection = (): JSX.Element => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    speed: 350,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 478,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className='main__fifth__container'>
      <Slider {...settings}>
        <div className='main__fifth__contents'>
          <div className='main__fifth__imgBox'>
            <img
              className='main__fifth__img'
              src={prada}
              alt='prada_logo'
            ></img>
          </div>
        </div>

        <div className='main__fifth__contents'>
          <div className='main__fifth__imgBox'>
            <img
              className='main__fifth__img'
              src={burberry}
              alt='burberry_logo'
            ></img>
          </div>
        </div>

        <div className='main__fifth__contents'>
          <div className='main__fifth__imgBox'>
            <img
              className='main__fifth__img'
              src={fendi}
              alt='fendi_logo'
            ></img>
          </div>
        </div>

        <div className='main__fifth__contents'>
          <div className='main__fifth__imgBox'>
            <img
              className='main__fifth__img'
              src={gucci}
              alt='gucci_logo'
            ></img>
          </div>
        </div>

        <div className='main__fifth__contents'>
          <div className='main__fifth__imgBox'>
            <img
              className='main__fifth__img'
              src={salvatore}
              alt='salvatore_logo'
            ></img>
          </div>
        </div>

        <div className='main__fifth__contents'>
          <div className='main__fifth__imgBox'>
            <img
              className='main__fifth__img'
              src={valentino}
              alt='valentino_logo'
            ></img>
          </div>
        </div>

        <div className='main__fifth__contents'>
          <div className='main__fifth__imgBox'>
            <img
              className='main__fifth__img'
              src={prada}
              alt='prada_logo'
            ></img>
          </div>
        </div>

        <div className='main__fifth__contents'>
          <div className='main__fifth__imgBox'>
            <img
              className='main__fifth__img'
              src={burberry}
              alt='burberry_logo'
            ></img>
          </div>
        </div>

        <div className='main__fifth__contents'>
          <div className='main__fifth__imgBox'>
            <img
              className='main__fifth__img'
              src={fendi}
              alt='fendi_logo'
            ></img>
          </div>
        </div>

        <div className='main__fifth__contents'>
          <div className='main__fifth__imgBox'>
            <img
              className='main__fifth__img'
              src={gucci}
              alt='gucci_logo'
            ></img>
          </div>
        </div>

        <div className='main__fifth__contents'>
          <div className='main__fifth__imgBox'>
            <img
              className='main__fifth__img'
              src={salvatore}
              alt='salvatore_logo'
            ></img>
          </div>
        </div>

        <div className='main__fifth__contents'>
          <div className='main__fifth__imgBox'>
            <img
              className='main__fifth__img'
              src={valentino}
              alt='valentino_logo'
            ></img>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default MainFifthSection;
