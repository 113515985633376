import React, { useState } from 'react';
import { Link } from 'react-router-dom';
let NewNoticeSecondSection = (): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');

  let queryHandler = (e: React.FormEvent<HTMLInputElement>) => {
    let query = e.currentTarget.value;
    setSearchQuery(query);
  };

  return (
    <div className='newNotice__second__contents'>
      {/* board */}

      <div className='newNotice__second__contentsBox'>
        <div className='newNotice__second__headBoardBox'>
          <ul className='newNotice__second__headBoard'>
            <li className='newNotice__second__boardHeadBox' key='headOfBoard'>
              <div className='newNotice__second__headNumber'>번호</div>
              <div className='newNotice__second__headTitle'>제목</div>
              <div className='newNotice__second__headWriter'>작성자</div>
              <div className='newNotice__second__headDate'>등록일</div>
              {/* <div className='newNotice__second__headView'>조회수</div> */}
            </li>
            {dummyData.map((notice) => (
              <Link to={`/newNotice/${notice.id}`}>
                <li className='newNotice__second__boardBox' key={notice.id}>
                  <div className='newNotice__second__boardNumber'>
                    {notice.number}
                  </div>
                  <div className='newNotice__second__boardTitle'>
                    {notice.title}
                  </div>
                  <div className='newNotice__second__boardWriter'>
                    {notice.writer}
                  </div>
                  <div className='newNotice__second__boardDate'>
                    {notice.date}
                  </div>
                  {/* <div className='newNotice__second__boardView'>
                    {notice.view}
                  </div> */}
                </li>
              </Link>
            ))}
          </ul>
        </div>
        {/* search bar */}
        {/* <div className='newNotice__second__searchBox'>
          <select className='newNotice__second__selet'>
            <option>작성자</option>
            <option>내용</option>
            <option>제목</option>
          </select>
          <input
            type='text'
            className='newNotice__second__searchInput'
            onChange={queryHandler}
          ></input>
          <button
            className='newNotice__second__searchBtn'
            onClick={searchHandler}
          >
            검색
          </button>
        </div> */}
      </div>
    </div>
  );
};

let dummyData = [
  {
    id: '1',
    number: '1',
    title: '벤처투자 관련, 2020년 근로소득 연말정산 안내',
    writer: '코빠밀뉴',
    date: '2021-01-15',
    // view: '0',
  },
  {
    id: '2',
    number: '2',
    title: '벤처기업으로 인증되었음을 알려드립니다.',
    writer: '코빠밀뉴',
    date: '2020-12-23',
    // view: '0',
  },

  {
    id: '3',
    number: '3',
    title: '신주발행 공고',
    writer: '코빠밀뉴',
    date: '2020-08-04',
    // view: '0',
  },
];

export default NewNoticeSecondSection;
