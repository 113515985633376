import React, { useEffect } from 'react';
import '../asset/main.scss';

import MainFirstSection from '../components/MainFirstSection';
import MainSecondSection from '../components/MainSecondSection';
import MainThirdSection from '../components/MainThirdSection';
import MainFourthSection from '../components/MainFourthSection';
import MainFifthSection from '../components/MainFifthSection';

let Main = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='main__container'>
      <MainFirstSection />
      <MainSecondSection />
      <MainThirdSection />
      <MainFourthSection />
      <MainFifthSection />
    </div>
  );
};

export default Main;
