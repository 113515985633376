import React from 'react';
import mimi from '../img/mimi.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import corea from '../img/main01.jpg';
import paris from '../img/main02.jpg';
import millan from '../img/main03.jpg';
import newyork from '../img/main04.jpg';
import { Link } from 'react-router-dom';

let MainFirstSection = (): JSX.Element => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
  };

  return (
    <div className='main__firstCarousel__container'>
      <Slider {...settings}>
        <div className='main__firstCarousel__contentBox'>
          <div className='main__firstCarousel__CoreaImg'></div>
          {/* <img className='main__firstCarousel__img' src={corea} alt='img'></img> */}
          <div className='main__firstCarousel__textBox'>
            <div className='main__firstCarousel__title'>Direct from Corea</div>
            <br />
            <span className='main__firstCarousel__copamilnew'>
              COPAMILNEW
            </span>{' '}
            <br />
            <span className='main__firstCarousel__country'>COREA</span>
            {/* <div className='main__firstCarousel__btnBox'>
              <Link to='/aboutUs/introduction'>
                <button className='main__firstCarousel__btn'>
                  ABOUT COMPANY
                </button>
                <button className='main__firstCarousel__btn'>
                  ONLINE SHOP
                </button>
              </Link>
            </div> */}
          </div>
        </div>

        <div className='main__firstCarousel__contentBox'>
          <div className='main__firstCarousel__parisImg'></div>
          {/* <img className='main__firstCarousel__img' src={paris} alt='img'></img> */}
          <div className='main__firstCarousel__textBox'>
            <div className='main__firstCarousel__title'>Direct from Paris</div>
            <br />
            <span className='main__firstCarousel__copamilnew'>
              COPAMILNEW
            </span>{' '}
            <br />
            <span className='main__firstCarousel__country'>PARIS</span>
            {/* <div className='main__firstCarousel__btnBox'>
              <Link to='/aboutUs/introduction'>
                <button className='main__firstCarousel__btn'>
                  ABOUT COMPANY
                </button>
              </Link>
              <button className='main__firstCarousel__btn'>ONLINE SHOP</button>
            </div> */}
          </div>
        </div>

        <div className='main__firstCarousel__contentBox'>
          {/* <img
            className='main__firstCarousel__img'
            src={millan}
            alt='img'
          ></img> */}
          <div className='main__firstCarousel__milanImg'></div>
          <div className='main__firstCarousel__textBox'>
            <div className='main__firstCarousel__title'>Direct from Milan</div>
            <br />
            <span className='main__firstCarousel__copamilnew'>
              COPAMILNEW
            </span>{' '}
            <br />
            <span className='main__firstCarousel__country'>MILAN</span>
            {/* <div className='main__firstCarousel__btnBox'>
              <Link to='/aboutUs/introduction'>
                <button className='main__firstCarousel__btn'>
                  ABOUT COMPANY
                </button>
              </Link>
              <button className='main__firstCarousel__btn'>ONLINE SHOP</button>
            </div> */}
          </div>
        </div>

        <div className='main__firstCarousel__contentBox'>
          {/* <img
            className='main__firstCarousel__img'
            src={newyork}
            alt='img'
          ></img> */}
          <div className='main__firstCarousel__newyorkImg'></div>
          <div className='main__firstCarousel__textBox'>
            <div className='main__firstCarousel__title'>
              Direct from New York
            </div>
            <br />
            <span className='main__firstCarousel__copamilnew'>
              COPAMILNEW
            </span>{' '}
            <br />
            <span className='main__firstCarousel__country'>NEW YORK</span>
            {/* <div className='main__firstCarousel__btnBox'>
              <Link to='/aboutUs/introduction'>
                <button className='main__firstCarousel__btn'>
                  ABOUT COMPANY
                </button>
              </Link>
              <button className='main__firstCarousel__btn'>ONLINE SHOP</button>
            </div> */}
          </div>
        </div>
      </Slider>
      {/* <div className='main__firstCarousel__btnBox'>
        <button className='main__firstCarousel__btn'>ABOUT COMPANY</button>
        <button className='main__firstCarousel__btn'>ONLINE SHOP</button>
      </div> */}
    </div>
  );
};

export default MainFirstSection;
