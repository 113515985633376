import React, { useEffect } from 'react';
import '../asset/dutyfree.scss';

import DutyFreeFirstSection from '../components/DutyFreeFirstSection';
import DutyFreeFourthSection from '../components/DutyFreeFourthSection';
import DutyFreeSecondSection from '../components/DutyFreeSecondSection';
import DutyFreeThirdSection from '../components/DutyFreeThirdSection';

let DutyFree = (): JSX.Element => {
  return (
    <div className='dutyFree__container'>
      <DutyFreeFirstSection />
      <DutyFreeSecondSection />
      <DutyFreeThirdSection />
      <DutyFreeFourthSection />
    </div>
  );
};

export default DutyFree;
