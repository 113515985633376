import React, { useEffect } from 'react';
import ContactFirstSection from '../components/ContactFirstSection';
import '../asset/contactUs.scss';
import ContactSecondSection from '../components/ContactSecondSection';

let ContactUs = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='contactUs__container'>
      <ContactFirstSection title='CONTACT US' />
      <ContactSecondSection />
    </div>
  );
};

export default ContactUs;
