import React, { useEffect, useState } from 'react';
import logoImgs from '../img/ecommerce__first__logoImgs.png';
import logoImgs2x from '../img/ecommerce__first__logoImgs2x.png';
import logoImgsMobile from '../img/ecommerce__first__logoImgs_mobile.png';
import logoImgsMobile2x from '../img/ecommerce__first__logoImgs_mobile2x.png';

interface Mall {
  title: string;
  link: string;
}

let EcommerceFirstSection = (): JSX.Element => {
  // let mallLink: Mall[] = [
  //   {
  //     title: '롯데닷컴 ',
  //     link:
  //       'https://www.lotteon.com/p/product/LD639866040&infw_disp_no_sct_cd=78&infw_disp_no=5505566&allViewYn=N?mall_no=1&ch_no=100299&ch_dtl_no=1000806&entryPoint=affl',
  //   },
  //   {
  //     title: '롯데i몰 ',
  //     link:
  //       'http://www.lotteimall.com/planshop/viewPlanShopDetail.lotte?spdp_no=5429558',
  //   },
  //   {
  //     title: 'AK몰',
  //     link:
  //       'https://www.akmall.com/planshop/PlanShopView.do?ctgId=201400&shop_event_id=1020527&urlpath=B_01@201400@201400',
  //   },
  //   { title: 'SSG.COM', link: 'http://www.pamilnew.com/' },
  //   { title: 'PAMILNEW.COM', link: 'http://www.pamilnew.com/' },
  // ];
  useEffect(() => {
    // console.log(screenWidth);
  }, []);
  return (
    <div className='ecommerce__first__contents'>
      <div className='ecommerce__first__contentsBox'>
        <h1>E-COMMERCE</h1>
        <div>
          <p>
            자사 온라인 몰 운영 및 종합몰 입점 / 해외 고객을 위한 직구몰 및
            모바일 앱을 통한 수출 추진 <br />
            Copmailnew는 글로벌 기업을 목표로 성장할 것입니다.
          </p>
          <p>
            온라인 사업부는 자사몰(PAMILNEW) 및 국내 굴징의 종합몰에서
            롯데닷컴,롯데아이몰,신세계몰,AK몰, 등에서 <br />
            명품을 고객에게 최고의 상품을 합리적인 가격에 제공하고 있습니다.
          </p>
        </div>
        <div className='ecommerce__first__logoImgs'></div>

        <img
          src={logoImgs}
          srcSet={`${logoImgs} 1x, ${logoImgs2x} 2x`}
          alt='logoImgsMobile'
          className='ecommerce__first__logoImgs'
        ></img>

        <img
          src={logoImgsMobile}
          srcSet={`${logoImgsMobile} 1x,${logoImgsMobile2x} 2x`}
          alt='logo_imgs'
          className='ecommerce__first__logoImgsMobile'
        ></img>

        {/* <div className='ecommerce__first__mallLinkBox'>
          {mallLink.map((el) => (
            <div>
              <a href={el.link} target='blank'>
                {el.title} &nbsp;&nbsp;|
              </a>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default EcommerceFirstSection;
