import React from 'react';

let DutyFreeThirdSection = (): JSX.Element => {
  return (
    <div className='dutyFree__third_container'>
      <div className='dutyFree__third_leftBox'>
        <h1>
          JDC 행복한
          <br />
          면세쇼핑의 시작.
        </h1>
        <p>
          15개 품목(면세점특례구정),260개 브랜드 <br />
          34,000여 상품취급 시중가보다 약 20%~60% 저렴
        </p>
      </div>
      <div className='dutyFree__third_rightBox'>
        <p>
          1990년대 후반, 제주도를 물류와 비지니스 거점인 국제자유도시로
          조성하자는 논의가 활발해졌습니다. 이러한 국제자유도시 제주 조성에
          국비가 아닌 재원 조달의 방안으로 JDC 지정면세점 오픈이 추진되었고,
          국내 최초의 JDC 지정 면세점이 제주에 자리잡게 되었습니다.
        </p>
      </div>
    </div>
  );
};

export default DutyFreeThirdSection;
