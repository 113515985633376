import './app.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';

import Nav from './pages/Nav';
import Footer from './pages/Footer';
import Main from './pages/Main';
// import AboutUs from './pages/AboutUs';
import DutyFree from './pages/DutyFree';
import ECommerce from './pages/ECommerce';
import ContactUs from './pages/ContactUs';
import NewNotice from './pages/NewNotice';
import LoginPage from './pages/LoginPage';
import Notice1 from './pages/Notice1';
import Notice2 from './pages/Notice2';
import Notice3 from './pages/Notice3';

let App = (): JSX.Element => {
  const [info, setInfo] = useState({ id: '', password: '' });

  const location = useLocation();
  let history = useHistory();

  let inputHandler = (e: any): any => {
    let type = e.target.dataset.type;
    let value = e.target.value;

    setInfo({ ...info, [type]: value });
  };

  let submitHandler = () => {
    try {
      // sever url 알맞게 넣기
      // sever url 알맞게 넣기
      // sever url 알맞게 넣기
      // sever url 알맞게 넣기
      // sever url 알맞게 넣기
      // sever url 알맞게 넣기
      axios
        .post(
          '/users/signin',
          { ...info },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        )
        .then((res) => {
          const { data } = res;
          localStorage.setItem('isLogin', JSON.stringify(true));
          history.push('/');
          window.location.reload();
        });
    } catch (error) {
      alert('잘못된 접근입니다.');
      console.log(error);
    }
  };

  const isElementUnderBottom = (el: any, triggerDiff: any) => {
    const { top } = el.getBoundingClientRect();
    const { innerHeight } = window;
    return top > innerHeight + (triggerDiff || 0);
  };

  const handleScroll = () => {
    const contents = document.querySelectorAll('.up-on-scroll');

    contents?.forEach((content: any) => {
      if (isElementUnderBottom(content, -120)) {
        content.style.opacity = '0';
        content.style.transform = 'translateY(100px)';
      } else {
        content.style.opacity = '1';
        content.style.transform = 'translateY(0px)';
      }
    });
  };

  window.addEventListener('scroll', handleScroll);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log(location);
  }, [location]);

  let navCategories = [
    {
      path: '/',
      component: <Main />,
    },
    {
      path: '/business/dutyfree_department',
      component: <DutyFree />,
    },
    {
      path: '/business/ecommerce',
      component: <ECommerce handleScroll={handleScroll} />,
    },
    {
      path: '/contact_us',
      component: <ContactUs />,
    },
    {
      path: '/new_notice',
      component: <NewNotice />,
    },
    {
      path: '/zhvkalfsbfhrmdls',
      component: (
        <LoginPage inputHandler={inputHandler} submitHandler={submitHandler} />
      ),
    },
    {
      path: '/newNotice/2',
      component: <Notice2 />,
    },
    {
      path: '/newNotice/3',
      component: <Notice3 />,
    },
    {
      path: '/newNotice/1',
      component: <Notice1 />,
    },
    // {
    //   path: '/aboutUs/introduction',
    //   component: <AboutUs />,
    // },
  ];

  return (
    <div className='app'>
      <Router>
        <Nav />
        {/*  */}
        <Route
          render={({ location }) => {
            return (
              <TransitionGroup>
                <CSSTransition
                  timeout={500}
                  classNames='fade'
                  key={location.key}
                >
                  <Switch location={location}>
                    {navCategories.map((el) => (
                      <Route exact path={el.path}>
                        {el.component}
                      </Route>
                    ))}
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            );
          }}
        ></Route>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
