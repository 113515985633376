import React, { useEffect } from 'react';
import '../asset/contactUs.scss';
import ContactFirstSection from '../components/ContactFirstSection';
import '../asset/notice1.scss';

function Notice3() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='notice__container'>
      <ContactFirstSection title='NEWS & NOTICE' />
      <div className='notice__contentBox'>
        <div className='notice__content'>
          <h1 className='notice__content__title'>신주발행 공고</h1>
          <p className='notice__content__context'>
            2020년 8월 4일에 개최한 주식회사 코빠밀뉴 이사회에서 아래와 같이
            신주발행을 결의하였음을 공고합니다.
            <br />
            <br />
            1. 신주식 종류와 수 : 기명식 보통주식 97,729주(단, 미청약 및
            미배정된 주식은 미발행함)
            <br />
            1. 신주식의 발행가격 : 1주의 금 2,200원(액면가 금 100원)
            <br />
            1. 신주의 납입총액 : 금 215,003,800원
            <br />
            1. 청약기간 : 2020년 8월 5일부터 2020년 8월 25일까지
            <br />
            1. 주금납입일 : 2020년 9월 4일
            <br />
            1. 납입을 받을 금융기관과 납입장소 : 국민은행 구로벤처센터
            <br />
            1. 신주권 교부(입고)일 : 2020년 10월 30일
            <br />
            1. 신주식의 배정방법 : 제3자 배정(정관 제9조 제2항에 근거)
            <br />
            1. 기타사항 : <br />
            &nbsp;&nbsp;가. 신주발행에 필요한 절차에 관한 사항은 대표자에게
            일임되다.
            <br />
            &nbsp;&nbsp;나. 위 일정은 관계기관과의 협의과정에서 변경될 수
            있다.2020년 8월 4일주식회사 코빠밀뉴서울특별시 강서구 양천로 583,
            에이동 402호
            <br />
            <br />
            대표이사 이 래 관
          </p>
        </div>
      </div>
    </div>
  );
}

export default Notice3;
