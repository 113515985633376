import React from 'react';
import '../asset/loginPage.scss';

type Props = {
  // inputInfo : {placeholder : string, type: string}[];
  inputHandler: (e: any) => void;
  submitHandler: () => void;
};

function LoginPage({ inputHandler, submitHandler }: Props) {
  return (
    <div className='login__container'>
      <div className='login__contentBox'>
        <div className='login__content'>
          <div className='login__content__category'>
            <div>아이디 : </div>
            <input type='text' data-type='id' onChange={inputHandler}></input>
          </div>
          <div className='login__content__category'>
            <div>비밀번호 : </div>
            <input
              type='password'
              data-type='password'
              onChange={inputHandler}
            ></input>
          </div>
          <button onClick={submitHandler}>Login</button>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
