import React from 'react';
import plane from '../img/dutyfree__fourth__plan.png';
import fourthImg from '../img/dutyfree__fourth1.png';
import fourth2XImg from '../img/dutyfree__fourth12x.png';

let DutyFreeFourthSection = (): JSX.Element => {
  return (
    <div className='dutyFree__fourth__container'>
      <div className='dutyFree__fourth__info'>
        <img src={plane} alt='plane img'></img>
        <h1>매출액 5,000억 돌파!</h1>
        <p className='dutyFree__fourth__infoDesc'>
          면세점 운영이 시작한 이래 다양하고 적극적인 마케팅을 추진하며 지속적인
          매출 시장을 기록하고 있습니다.
          <br />
          특히,'16년에는 매출액 5,407억원을 기록하며 역대 최고치를 기록했습니다.
          <br />
          이러한 배경에는 꾸준한 제도개선 추진노력이 있었습니다.
          <br />
          면세점 이용연령 제한 폐지, 구매한도 상향 등의 노력을 통해 국제자유도시
          조성에 필요한 시드머니 마련에 박차를 가하고 있습니다.
          <br />
          프로젝트 단위의 컨설팅이 아닌 비즈니스 단위의 컨설팅을 하는 진정한
          전문가가 있습니다.
          <br />
        </p>
      </div>
      <img
        src={fourthImg}
        srcSet={`${fourthImg} 1x, ${fourth2XImg} 2x`}
        alt='img'
        className='dutyFree__fourth__infoImg'
      ></img>
    </div>
  );
};

export default DutyFreeFourthSection;
