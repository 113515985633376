import React, { useEffect } from 'react';
// import dotenv from 'dotenv';

// dotenv.config();
declare global {
  interface Window {
    kakao: any;
  }
}

const HeadOfficeMap = (): JSX.Element => {
  useEffect(() => {
    let container = document.querySelector(
      '.contactUs__second__branchOfficeMap'
    ); //지도를 담을 영역의 DOM 레퍼런스
    // let container = document.getElementById('map'); //지도를 담을 영역의 DOM 레퍼런스
    let options = {
      //지도를 생성할 때 필요한 기본 옵션
      center: new window.kakao.maps.LatLng(
        37.55719507367589,
        126.8640728251299
      ), //지도의 중심좌표.
      level: 3, //지도의 레벨(확대, 축소 정도)
    };

    let map = new window.kakao.maps.Map(container, options); //지도 생성 및 객체 리턴

    let markerPosition = new window.kakao.maps.LatLng(
      37.55719507367589,
      126.8640728251299
    );
    var marker = new window.kakao.maps.Marker({
      position: markerPosition,
    });
    marker.setMap(map);
  }, []);

  return (
    <>
      <div className='contactUs__second__branchOfficeMap' />
    </>
  );
};

export default HeadOfficeMap;
