import React from 'react';
import dutyfree from '../img/main__third1.jpg';
import ecommerce from '../img/main__third2.jpg';
import asia from '../img/main__third3.jpg';

interface Work {
  img: string;
  num: string;
  title: string;
  desc1: string;
  desc2: string;
  location: string;
}

let MainThirdSection = (): JSX.Element => {
  let work: Work[] = [
    {
      img: dutyfree,
      num: '01',
      title: 'DUTY FREE DEPARTMENT',
      desc1: '국토부 산하 공기업인 JDC지정면세점 상품 납품 및 입점',
      desc2: '세계유명브랜드인 PRADA,GUCCI,FERREGAMO 잡화 유통 판매중.',
      location: '김포,인천공함 면세점 입점 추진.',
    },
    {
      img: ecommerce,
      num: '02',
      title: 'E-COMMERCE',
      desc1: '국토부 산하 공기업인 JDC지정면세점 상품 납품 및 입점',
      desc2: '세계유명브랜드인 PRADA,GUCCI,FERREGAMO 잡화 유통 판매중.',
      location: '김포,인천공함 면세점 입점 추진.',
    },
    {
      img: asia,
      num: '03',
      title: 'EXPORT TO EAST ASIA',
      desc1: '중국 CDFS 및 GDFS 보세 면세점 상품공급',
      desc2: '홍콩,베트남,인도네시아 수출 추진',
      location: '중국 등 외국인 전용 직구몰/모바일 앱 운영.',
    },
  ];

  return (
    <div className='main__third__container'>
      <div className='main__third__titleBox up-on-scroll'>
        <div className='main__third__title'>WHY CHOOSE US?</div>
        <div className='main__third__subTitle'>주요 운영 사업</div>
      </div>

      <div className='main__third__contentBox'>
        {work.map((el) =>
          Number(el.num) % 2 === 0 ? (
            <div className='main__third__content reverse up-on-scroll'>
              {/* <img
              className='main__third__contentImg'
              src={el.img}
              alt={el.title}
            /> */}
              <div className='main__third__contentInfoBox'>
                <div className='main__third__contentInfoNumber'>{el.num}</div>
                <div className='main__third__contentInfoTitle'>{el.title}</div>
                <div className='main__third__contentInfoDesc'>{el.desc1}</div>
                <div className='main__third__contentInfoDesc'>{el.desc2}</div>
                <div className='main__third__contentInfoDesc'>
                  {el.location}
                </div>
                {/* <button className='main__third__contentInfoBtn'>
                EXPLORE SERVICE
              </button> */}
              </div>
              <img
                className='main__third__contentImg'
                src={el.img}
                alt={el.title}
              />
            </div>
          ) : (
            <div className='main__third__content  up-on-scroll'>
              <img
                className='main__third__contentImg'
                src={el.img}
                alt={el.title}
              />
              <div className='main__third__contentInfoBox'>
                <div className='main__third__contentInfoNumber'>{el.num}</div>
                <div className='main__third__contentInfoTitle'>{el.title}</div>
                <div className='main__third__contentInfoDesc'>{el.desc1}</div>
                <div className='main__third__contentInfoDesc'>{el.desc2}</div>
                <div className='main__third__contentInfoDesc'>
                  {el.location}
                </div>
                {/* <button className='main__third__contentInfoBtn'>
              EXPLORE SERVICE
            </button> */}
              </div>
              {/* <img
            className='main__third__contentImg'
            src={el.img}
            alt={el.title}
          /> */}
            </div>
          )
        )}
        {/* reverse */}
      </div>
    </div>
  );
};

export default MainThirdSection;
