import React, { useEffect } from 'react';
import '../asset/newNotice.scss';
import '../asset/contactFirst.scss';
import ContactFirstSection from '../components/ContactFirstSection';
import NewNoticeSecondSection from '../components/NewNoticeSecondSection';

let NewNotice = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='newNotice__container'>
      <ContactFirstSection title='NEWS & NOTICE' />
      <NewNoticeSecondSection />
    </div>
  );
};

export default NewNotice;
