import React, { useEffect } from 'react';
import '../asset/contactUs.scss';
import ContactFirstSection from '../components/ContactFirstSection';
import '../asset/notice1.scss';

function Notice1() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='notice__container'>
      <ContactFirstSection title='NEWS & NOTICE' />
      <div className='notice__contentBox'>
        <div className='notice__content'>
          <h1 className='notice__content__title'>
            벤처투자 관련, 2020년 근로소득 연말정산 안내
          </h1>
          <p className='notice__content__context'>
            안녕하십니까 ㈜코빠밀뉴입니다.
            <br /> <br />
            투자자 여러분 2021년 새해 복 많이 받으시고 좋은 일만 가득하시기를
            기원드립니다.
            <br />
            <br />
            2020년 와디즈를 통하여 투자해 주신 분들은 조세특례제한법제 16조(벤처
            투자조합 출자 등에 대한 소득공제)에 따라 근로소득 연말정산 시
            소득공제를 받을 수 있습니다.
            <br />
            소득공제에 필요한 투자확인서를 발급받기 위하여 투자자분들의 “개인
            정보 수집 이용 제공동의서 ” 를 당사에 제공해 주셔야 합니다. 당사
            도착 기준으로 2021년 1월 22 일까지 원본을 우편으로 또는 스캔본을
            메일로 제공해 주시면 당사에서 투자확인서를 일괄 신청하여
            드리겠습니다.
            <br />
            <br />
            투자확인서는 신청 시점부터 발급까지 7 영업일이 소요되는 만큼, 1/25일
            기후 도착분에 대하여서는 이번에는 발급 신청해드리지 못하는 점 양해
            부탁드리겠습니다.
            <br />
            또한 2020 년 연말정산에 소득공제를 신청하지 않으실 분들은 “ 소득공제
            시기 변경 신청서 ” 를 보내주시면, 신청해드리겠습니다. 시기 변경 신청
            시에도 개인 정보 수집 이용 제공동의서는 제공해 주셔야 합니다.
            <br />
            <br />
            투자확인서 관련 자세한 사항은 소득공제 FAQ(
            <a href='https://tax.kban.or.kr/svc/info/faq.do'>
              <span>www.tax.kban.or.kr/svc/info/faq.do</span>
            </a>
            )를 참고해주세요 . <br />
            양식 다운로드 : 엔젤투자지원센터 (
            <a href='https://www.kban.or.kr'>www.kban.or.kr</a>
            ) <br />
            보내실 곳 주소 : 서울시 강서구 양천로 583, 우림블루나인 A동 402호
            (담당자:김지혜 주임{' '}
            <a href='mailto:rere1631@naver.com'>rere1631@naver.com</a> )
            <br />
            <br />
            앞으로도 많은 관심과 성원을 부탁드리겠습니다.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Notice1;

{
  /* 벤처투자관련,2020년근로소득연말정산안내 dlinnovation 2021-01-15144 안녕하십니까 ㈜코빠밀뉴입니다 투자자여러분   2021  년새해복많이받으시고좋은일만가득하시기를기원드립니다 2020  년와디즈를통하여투자해주신분들은조세특례제한법제 16  조 (  벤처투자조합출자등에대한소득공제 )  에  따라근로소득연말정산시소득공제를받을수있습니다 .     소득공제에필요한투자확인서를발급받기위하여  투자자분들의 “  개인정보
수집이용제공동의서 ”  를당사에제공해주셔야합니다 .   당사도착기준으로 2021  년 1  월
 22  일까지원본을우편으로또는스캔본을메일로제공해주시면  당사에서투자확인서를일괄신청하여드리겠습니다 . &amp;  투자확인서는신청시점부터발급까지 7  영업일이소요되는만큼   1 25  일이후도착분에대하여서는이번에는발급신청해드리지못하는점   양해부탁드리겠습니다 .      또한 2020  년연말정산에소득공제를신청하지않으실분들은  “  소득공제시기변경신청서 ”  를보내주시면 ,  신청해드리겠습니다 . 시기변경신청시에도개인정보수집이용제공동의서는
제공해주셔야합니다 .     투자확인서관련자세한사항은소득공제 FAQ( ahref=https:  tax.kban.or.kr svc info faq.dohttps:  tax.kban.or.kr svc info faq.do  a)
  를참고해주세요 .     양식다운로드 :  엔젤투자지원센터 ( ahref=https:  www.kban.or.kr https:  www.kban.or.kr  a)   보내실곳주소:서울시강서구양천로583,우림블루나인A동402호  (담당자:김지혜주임rere1631@naver.com)   앞으로도많은관심과성원을부탁드리겠습니다 .     */
}
