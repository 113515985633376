import React from 'react';
// import mimi from '../img/mimi.png';
import dutyfree from '../img/main__second1.jpg';
import ecommerce from '../img/main__second2.jpg';
import cosmetic from '../img/main__second3.jpg';
import pamilnew from '../img/main__second4.jpg';

let MainSecondMall = (): JSX.Element => {
  let info = [
    {
      img: dutyfree,
      title: 'DUTYFREE DEPARTMENT',
      desc: `JDC면세점, 명품편집샵 'BUTTI'운영`,
    },
    {
      img: ecommerce,
      title: 'E-COMMERCE',
      desc: '자사몰(PAMILNEW) 및 종합몰 운영',
    },
    {
      img: cosmetic,
      title: 'EXPORT TO EAST ASIA',
      desc: '글로벌 화장품 중국 수출 및 국산 화장품 수출',
    },
    {
      img: pamilnew,
      title: 'COPAMILNEW Co.',
      desc: '글로벌 및 국산 화장품 수출',
    },
  ];
  return (
    <div className='main__second__imgContent'>
      {info.map((el) => (
        <div className='main__second__imgBox  up-on-scroll'>
          <img className='main__second__img' src={el.img} alt={el.title}></img>
          <div className='main__second__detailBox'>
            <div className='main__second__detailTitle'>{el.title}</div>
            <div className='main__second__detailDesc'>{el.desc}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MainSecondMall;
