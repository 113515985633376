import React from 'react';
import MainSecondMall from './MainSecondMall';

let MainSecondSection = (): JSX.Element => {
  return (
    <div className='main__second__container'>
      <div className='main__second__titleBox  up-on-scroll'>
        <div className='main__second__title'>WHY CHOOSE US?</div>
        <div className='main__second__subTitle'>
          직소싱을 통한 경쟁력있는 가격 &<br />
          국가기관에서 보증된 정품
        </div>
      </div>
      <MainSecondMall />
    </div>
  );
};

export default MainSecondSection;
