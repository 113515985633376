import React from 'react';
import workProcess from '../img/main__fourth1.png';
import howTo from '../img/main__fourth2.png';
import ContactUs from '../img/main__fourth3.png';

let MainFourthSection = (): JSX.Element => {
  let info = [
    {
      img: workProcess,
      title: 'WORK PROCESS',
      desc1: `면세점유통,온라인유통,수출사업`,
      desc2: `명품을 직 소싱하여 유통 중.`,
    },
    {
      img: howTo,
      title: 'HOW TO',
      desc1: `Parallel`,
      desc2: `Import & Export`,
    },
    {
      img: ContactUs,
      title: 'CONTACT US',
      desc1: `WWW.PAMILNEW.COM`,
      desc2: `WWW.PAMILNEW.CO.KR`,
    },
  ];

  let news = [
    {
      title: '벤처투자 관련, 2020년 근로소득 연말..',
      link: '',
      date: '2021-01-15',
    },
    {
      title: '벤처기업으로 인증되었음을 알려드립니..',
      link: '',
      date: '2020-12-23',
    },
    {
      title: '신주발행 공고',
      link: '',
      date: '2020-08-04',
    },
  ];

  return (
    <div className='main__fourth__container'>
      <div className='main__fourth__infoContents'>
        <div className='main__fourth__titleBox'>
          <div className='main__fourth__title'>
            ABOUT COPAMILNEW CORPORATION
          </div>
          <div className='main__fourth__subTitle'>
            글로벌 NO.1 소싱, 유통 전문회사
          </div>
        </div>
        {/*  */}
        <div className='main__fourth__infoBox'>
          {info.map((el) => (
            <div className='main__fourth__detailInfo'>
              <img
                className='main__fourth__infoImg'
                src={el.img}
                alt={el.title}
              ></img>

              <div className='main__fourth__infoTitle'>{el.title}</div>

              <div className='main__fourth__infoDesc'>
                {el.desc1}
                <br />
                {el.desc2}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <div className='main__fourth__newsContent'>
        {news.map((el) => (
          <div className='main__fourth__news'>
            <a href={el.link}>{el.title}</a>
            <div>{el.date}</div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default MainFourthSection;
